<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="../../assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>

      <div class="info-box" v-loading="ifOpen">
        <div class="head-card">
          <div class="head-card-left">
            <img class="shadow" src="../../assets/img/wang/shadow.png" />
          </div>
          <div class="head-card-right">
            <div class="title-box">
              <div class="title-box-left" v-if="myinfo.warningFlag">
                <div>已预警</div>
              </div>
              <div class="title-box-right" v-html="myinfo.title ? myinfo.title : ''"></div>
            </div>
            <div class="early-warning">
              <img class="header-img" v-if="myinfo.artificialMessageTypesName"
                   src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202004%2F14%2F20200414210224_dnzpo.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672910975&t=4f572bc1a2f09b5a21a599062a8883e0" />
              <el-popover placement="right-start" popper-class="monitor-yt-popover" effect="dark" width="400" trigger="hover">
                <div class="popover">
                  <div class="popover-type m-l-16 m-t-16" v-for="(value, inx) in xinxiTypeList" :key="inx">
                    <el-tooltip class="item" effect="dark" placement="top-start">
                      <div slot="content">{{ value }}</div>
                      <div class="texthidden">{{ value }}</div>
                    </el-tooltip>
                  </div>
                </div>
                <div slot="reference">
                  <div class="type" v-if="xinxiTypeList.length > 0">
                    {{ xinxiTypeList[0] }}
                  </div>
                </div>
              </el-popover>
              <div class="platform-type m-l-16">{{ myinfo.platformName }}</div>
              <img class="read" v-if="myinfo.readFlag" src="../../assets/img/wang/yidu.png" alt="" />
              <img class="read" v-else src="../../assets/img/wang/weidu.png" alt="" />
              <div class="text" v-if="myinfo.readFlag">已读</div>
              <div v-else style="color: #ea3342">未读</div>
              <div class="time m-l-16">发布时间：{{ myinfo.publishTime }}</div>
            </div>
            <div class="operation">
              <div class="copy" @click="
                  $main.copyFun(
                    myinfo.content + ' ' + myinfo.sourceUrl,
                    '复制链接和标题'
                  )
                ">
                <img src="../../assets/img/wang/copy-link.png" />
                <span>复制内容和链接</span>
              </div>
              <div class="copy" @click="createImgTask" :class="{'disabled':imgUrl}">
                <img src="../../assets/img/wang/jietu.png" />
                <span>页面截图</span>
              </div>
              <div class="copy" @click="operation({ type: 1, item: myinfo })" v-if="!myinfo.collectFlag">
                <img src="../../assets/img/wang/collection.png" />
                <span>加入收藏</span>
              </div>
              <div class="copy" v-else @click="operation({ type: 6, item: myinfo })">
                <img src="../../assets/img/zhang/yishoucang.png" />
                <span>已收藏</span>
              </div>
              <div class="copy" @click="deleteFnMy(myinfo)">
                <img src="../../assets/img/wang/del.png" />
                <span>删除</span>
              </div>
              <!-- <div class="copy" @click="toDetail(2)">
                <img src="../../assets/img/wang/edit.png" />
                <span>开展处置</span>
              </div> -->
            </div>
          </div>
          <img class="shadow2" src="../../assets/img/wang/shadow2.png" />
          <img v-if="myinfo.disposeStatus==2" class="disposed" src="../../assets/img/wang/disposed.png" alt="" />
          <img v-if="myinfo.disposeStatus==1" class="disposed" src="../../assets/img/wang/chuzhizhong.png" alt="" />
          <img v-if="myinfo.disposeStatus==0" class="disposed" src="../../assets/img/wang/undisposed.png" alt="" />
        </div>
        <div class="platform">
          <div class="platform-name p-r-30">
            <span class="textHidden1">
              原文链接：
              <a class="aColor cursor" @click="$main.lookYuanWen(myinfo.sourceUrl)">{{ myinfo.sourceUrl }}</a>
            </span>
            <!-- <img
                @click="$main.copyFun(myinfo.sourceUrl, '原文链接')"
                src="../../assets/img/wang/copy-all.png"
              /> -->
          </div>
          <div class="platform-name">
            <span>平台类型：{{ myinfo.platformName }}</span>
            <!-- <img
              @click="$main.copyFun(myinfo.platformName, '复制平台类型')"
              src="../../assets/img/wang/copy-all.png"
            /> -->
          </div>
          <div class="platform-name" v-if="myinfo.platformType == 1">
            <span>网站名称：{{ myinfo.source || "无" }}</span>
            <!-- <img
              @click="
                $main.copyFun(myinfo.source, '网站名称')
              "
              src="../../assets/img/wang/copy-all.png"
            /> -->
          </div>
          <div class="platform-name" v-else>
            <span>作者：{{ myinfo.author || "无" }}</span>
            <!-- <img
              @click="$main.copyFun(myinfo.author, '复制作者')"
              src="../../assets/img/wang/copy-all.png"
            /> -->
          </div>
          <div class="platform-name">
            <span>问题类型：{{
                myinfo.artificialMessageTypesName ||
                myinfo.machineMessageTypesName ||
                "无"
              }}</span>
            <!-- <img
              @click="
                $main.copyFun(
                  myinfo.artificialMessageTypesName ||
                    myinfo.machineMessageTypesName,
                  '问题类型'
                )
              "
              src="../../assets/img/wang/copy-all.png"
            /> -->
          </div>
          <div class="img" v-loading="loadingImg" element-loading-text="正在截图中">
            <el-image v-if="imgUrl" @load="imgLoad" :src="imgUrl" class="img" :preview-src-list="[imgUrl]">
            </el-image>
            <img v-else class="img" src="../../assets/img/gjh/head-r.png" alt="" srcset="" />
          </div>
        </div>

      </div>
      <div class="disposal m-t-16">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="信息内容" name="1">
            <!-- 内容 -->
            <div class="content content-desc" v-html="myinfo.content ? myinfo.content : '无'"></div>
          </el-tab-pane>
          <el-tab-pane label="有害信息处置详情" name="2">
            <el-card class="box-card" shadow="never" v-for="item,index in chuzhiData" :key="index">
              <div slot="header" class="clearfix">
                <span>处置记录{{index*1+1}}</span>
                <div class="right-btn-div">
                  <el-button :disabled='item.content.carryOut||item.content.misjudgmentAdjustment?true:false' class="btn1 btn"
                             @click="misjudge(item)">误判更正</el-button>
                  <el-button :disabled='item.content.carryOut||item.content.disposeComplete||item.content.misjudgmentAdjustment?true:false' class="btn2 btn"
                             @click="updateDis1(item,1)">开展处置</el-button>
                  <el-button :disabled='(!item.content.carryOut&&!item.content.misjudgmentAdjustment)||item.content.disposeComplete?true:false' class="btn3 btn"
                             @click="updateDis1(item,2)">处置完成</el-button>
                </div>
              </div>
              <div class="dis-cont">
                <div class="dis-type" v-if="item.content.discoveryAbnormal">
                  <div class="first-row row">
                    <div class="type-name">发现异常状态</div>
                    <div class="type-edit" v-if="item.content.discoveryAbnormal.is_dis_edit" @click="disEditShow1(item.content.discoveryAbnormal,0,item)">编辑
                    </div>
                    <div class="type-edit-list" v-else>
                      <el-button type="warning" plain size="mini" class="type-edit-no" @click="disEditHide1(item.content.discoveryAbnormal)">取消</el-button>
                      <el-button type="warning" plain size="mini" class="type-edit-yes" @click="updateOk(item.content.disposeComplete)">确定</el-button>
                    </div>
                  </div>
                  <template v-if="item.content.discoveryAbnormal.is_dis_edit">
                    <div class="qita-row row">
                      <span>异常状态：</span>
                      <span>{{item.content.discoveryAbnormal.abnormalStatusName}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>发现方式：</span>
                      <span>{{item.content.discoveryAbnormal.discoveryMode}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>发现时间：</span>
                      <span>{{item.content.discoveryAbnormal.discoveryTime}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <el-row>
                      <el-col :span="6">
                        异常状态
                      </el-col>
                      <el-col :span="18">
                        <el-select v-model="disposeObj1.abnormalStatus" placeholder="请选择" size="small" value-key="value">
                          <el-option v-for="item in abnormalStatusList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        发现方式
                      </el-col>
                      <el-col :span="18">
                        <el-input v-model="disposeObj1.discoveryMode" class="input" placeholder="请选择" size="small" clearable></el-input>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        发现时间
                      </el-col>
                      <el-col :span="18">
                        <el-date-picker v-model="disposeObj1.discoveryTime" type="datetime" size="small" placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                      </el-col>
                    </el-row>
                  </template>
                </div>
                <div class="divider"></div>
                <div class="dis-type" v-if="item.content.misjudgmentAdjustment">
                  <div class="first-row row">
                    <div class="type-name">误判调整</div>
                  </div>
                  <div class="qita-row row">
                    <span>异常状态：</span>
                    <span>正常</span>
                  </div>
                  <div class="qita-row row">
                    <span>变更原因：</span>
                    <span>{{item.content.misjudgmentAdjustment.changeReason}}</span>
                  </div>
                  <div class="qita-row row">
                    <span>变更时间：</span>
                    <span>{{item.content.misjudgmentAdjustment.changeTime}}</span>
                  </div>
                </div>
                <div class="dis-type" v-if="item.content.carryOut">
                  <div class="first-row row">
                    <div class="type-name">开展处置工作</div>
                    <div class="type-edit" v-if="item.content.carryOut.is_dis_edit" @click="disEditShow2(item.content.carryOut,1,item)">编辑</div>
                    <div class="type-edit-list" v-else>
                      <el-button type="warning" plain size="mini" class="type-edit-no" @click="disEditHide2(item.content.carryOut)">取消</el-button>
                      <el-button type="warning" plain size="mini" class="type-edit-yes" @click="updateOk(item.content.disposeComplete)">确定</el-button>
                    </div>
                  </div>
                  <template v-if="item.content.carryOut.is_dis_edit">
                    <div class="qita-row row">
                      <span>处置方式：</span>
                      <span>{{item.content.carryOut.disposeModeName}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>处置内容：</span>
                      <span>{{item.content.carryOut.disposeContent}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>处置人员：</span>
                      <span>{{item.content.carryOut.checker}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>处置时间：</span>
                      <span>{{item.content.carryOut.disposeTime}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <el-row>
                      <el-col :span="6">
                        处置方式
                      </el-col>
                      <el-col :span="18">
                        <el-select v-model="disposeObj2.disposeMode" placeholder="请选择" size="small">
                          <el-option v-for="item in disposeModeList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        处置内容
                      </el-col>
                      <el-col :span="18">
                        <el-input v-model="disposeObj2.disposeContent" class="input" placeholder="请选择" size="small" clearable></el-input>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        处置时间
                      </el-col>
                      <el-col :span="18">
                        <el-date-picker v-model="disposeObj2.disposeTime" type="datetime" size="small" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                      </el-col>
                    </el-row>
                  </template>
                </div>
                <div class="divider"></div>
                <div class="dis-type" v-if="item.content.disposeComplete">
                  <div class="first-row row">
                    <div class="type-name">完成处置工作</div>
                    <div class="type-edit" v-if="item.content.disposeComplete.is_dis_edit" @click="disEditShow3(item.content.disposeComplete,2,item)">编辑</div>
                    <div class="type-edit-list" v-else>
                      <el-button type="warning" plain size="mini" class="type-edit-no" @click="disEditHide3(item.content.disposeComplete)">取消</el-button>
                      <el-button type="warning" plain size="mini" class="type-edit-yes" @click="updateOk(item.content.disposeComplete)">确定</el-button>
                    </div>
                  </div>
                  <template v-if="item.content.disposeComplete.is_dis_edit">
                    <div class="qita-row row">
                      <span>处置结果：</span>
                      <span>{{item.content.disposeComplete.disposeResultName}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>处置内容：</span>
                      <span>{{item.content.disposeComplete.disposeContent}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>核查人员：</span>
                      <span>{{item.content.disposeComplete.checker}}</span>
                    </div>
                    <div class="qita-row row">
                      <span>完成时间：</span>
                      <span>{{item.content.disposeComplete.completeTime}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <el-row>
                      <el-col :span="6">
                        处置结果
                      </el-col>
                      <el-col :span="18">
                        <el-select v-model="disposeObj3.disposeResult" placeholder="请选择" size="small">
                          <el-option v-for="item in disposeResultList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        处置内容
                      </el-col>
                      <el-col :span="18">
                        <el-input v-model="disposeObj3.disposeContent" class="input" placeholder="请选择" size="small" clearable></el-input>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        完成时间
                      </el-col>
                      <el-col :span="18">
                        <el-date-picker v-model="disposeObj3.completeTime" type="datetime" size="small" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                      </el-col>
                    </el-row>
                  </template>
                </div>
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 相似信息 -->
      <div class="be-similar">
        <div class="be-similar-account">
          <span>相似信息</span><span>（{{ likenum }}条）</span>
          <div class="be-similar-color"></div>
        </div>
        <Bitmap v-if="likeList.length == 0"></Bitmap>
        <div class="data-box" v-for="(item, index) in likeList" :key="index">
          <div class="data-box-right">
            <div class="name-box">
              <div class="name-box-left">
                <el-popover placement="right-start" popper-class="monitor-yt-popover" effect="dark" width="400" trigger="hover">
                  <div class="popover">
                    <div class="popover-type m-l-16 m-t-16" v-for="(value, inx) in item.xinxiTypeList" :key="inx">
                      <el-tooltip class="item" effect="dark" placement="top-start">
                        <div slot="content">{{ value }}</div>
                        <div class="texthidden">{{ value }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div slot="reference">
                    <div class="type" v-if="item.xinxiTypeList.length > 0">
                      {{ item.xinxiTypeList[0] }}
                    </div>
                    <div class="type m-t-12" v-if="item.xinxiTypeList.length > 1">
                      {{ item.xinxiTypeList[1] }}
                    </div>
                  </div>
                </el-popover>
                <div class="name" @click="toDetail(1,item.id)">
                  {{ item.title || "无" }}
                </div>
              </div>
              <div class="time">{{ item.publishTime || "无" }}</div>
            </div>
            <div class="data-info">
              <div class="subject-type">
                平台类型：{{ item.platformName || "无" }}
              </div>
              <div class="region">所属区域：{{ item.areaName || "无" }}</div>
              <div class="subject-type" v-if="item.platformType == 1">
                网站名称：{{ item.source || "无" }}
              </div>
              <div v-else class="company">
                作者名称：<span v-html="item.author || '无'"></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="operation"></deleteDialog>
    <!-- 处置完成 -->
    <el-dialog :visible.sync="disposeVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">{{dialogTitle}}</span>
      </div>
      <div class="dialog-body" style="padding: 0px 40px 0 40px">
        <div class="dialog-body-li">
          <div class="dialog-body-li-l">
            <span><span class="red">*</span> 处置方式</span>
          </div>
          <div class="dialog-body-li-r" v-if="dialogTitle=='开展处置'">
            <el-select v-model="disDialogObj.disposeMode" placeholder="请选择" size="small" style="width: 100%">
              <el-option v-for="item in disposeModeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-body-li-r" v-else>
            <el-select v-model="disDialogObj.disposeResult" placeholder="请选择" size="small" style="width: 100%">
              <el-option v-for="item in disposeResultList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l"><span class="red">*</span>处置内容</div>
          <div class="dialog-body-li-r">
            <el-input type="textarea" :rows="2" size="small" placeholder="请输入内容" v-model="disDialogObj.disposeContent">
            </el-input>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l"><span class="red">*</span>处置时间</div>
          <div class="dialog-body-li-r" v-if="dialogTitle=='开展处置'">
            <el-date-picker v-model="disDialogObj.disposeTime" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="dialog-body-li-r" v-else>
            <el-date-picker v-model="disDialogObj.completeTime" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disposeVisible=false" size="small">取 消
        </el-button>
        <el-button type="primary" size="small" @click="updateOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Bitmap from "../../components/bitmap.vue";
import deleteDialog from "../../components/deleteDialog.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import { detail, similarTitle, update, disposeRecord, updateDispose } from "../../api/harmful/index";
import { createImgTask } from "../../api/createImgTask/index";
export default {
  components: { Breadcrumb, deleteDialog, Bitmap },
  data() {
    return {
      xinxiTypeList: [],
      routerList: ["有害信息", "详情"],
      dataId: null,
      myinfo: {}, // 详情信息
      likeList: [], // 相似列表
      likenum: 0, // 相似列表总数
      ifOpen: true, //加载中
      baseUrl: window.localStorage.getItem('baseURL'),
      websock: null,
      imgUrl: '',
      loadingImg: false,
      imgLoading: true,


      activeName: '1',//tab选项
      is_dis_edit1: true,//发现异常
      is_dis_edit2: true,//开展处置
      is_dis_edit3: true,//处置完成
      abnormalStatusList: [],//异常状态
      disposeModeList: [],
      disposeResultList: [],
      //弹框绑定属性
      disDialogObj: {
        disposeMode: '',
        disposeTime: '',

        disposeContent: '',

        disposeResult: '',
        completeTime: ''
      },
      //开展处置
      disposeObj1: {
        abnormalStatus: '',//异常状态
        abnormalStatusName: '',
        discoveryMode: '',//发现方式
        discoveryTime: ''//发现时间
      },
      disposeObj2: {
        disposeMode: '',
        disposeContent: '',
        disposeTime: ''
      },
      disposeObj3: {
        disposeResult: '',
        disposeContent: '',
        completeTime: '',
      },
      disposeVisible: false,//处置弹框
      dialogTitle: '开展处置',
      chuzhiData: {},//处置记录
      step: 1,//开展处置:1  处置完成:2
      disId: '',//处置记录id
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.id;
    }
    if(params.activeName)this.activeName = params.activeName
    this.abnormalStatusList = JSON.parse(localStorage.getItem('message_type'))
    this.disposeModeList = JSON.parse(localStorage.getItem('harmful_dispose_mode'))
    this.disposeResultList = JSON.parse(localStorage.getItem('harmful_dispose_result'))
    this.getdetail();
    this.disposeRecord()
  },
  methods: {

    // 处置方式编辑的显隐----开展处置
    disEditShow1(item, val, obj) {
      console.log(item);
      this.step = val
      this.disId = obj.id
      this.disposeObj1 = {
        abnormalStatus: item.abnormalStatus + '',
        discoveryMode: item.discoveryMode,
        discoveryTime: item.discoveryTime,
      }
      item.is_dis_edit = false
    },
    disEditHide1(item) {
      item.is_dis_edit = true
    },
    //开展处置弹框
    updateDis1(item, val) {
      console.log(item, '开展处置');
      this.disposeVisible = true
      this.dialogTitle = val == 1 ? '开展处置' : '处置完成'
      this.step = val
      this.disId = item.id
      //置空
      this.disDialogObj = {
        disposeMode: '',
        disposeTime: '',

        disposeContent: '',

        disposeResult: '',
        completeTime: ''
      }
    },
    // 弹框确定事件  处置确定事件
    async updateOk(show = '') {//有show代表编辑
      let data = {}
      if (this.step == 0) {//发现异常0
        data = {
          id: this.disId,
          content: {
            discoveryAbnormal: {
              abnormalStatus: this.disposeObj1.abnormalStatus,
              discoveryMode: this.disposeObj1.discoveryMode,
              discoveryTime: this.disposeObj1.discoveryTime,
            }
          }
        }
        if(!data.content.discoveryAbnormal.abnormalStatus){
          this.$message.warning('异常状态不能为空')
          return
        }
        if(!data.content.discoveryAbnormal.discoveryMode){
          this.$message.warning('发现方式不能为空')
          return
        }
        if(!data.content.discoveryAbnormal.discoveryTime){
          this.$message.warning('发现时间不能为空')
          return
        }
      }
      if (this.step == 1) {//开展处置1
        data = {
          id: this.disId,
          content: {
            carryOut: {
              disposeMode: this.disDialogObj.disposeMode || this.disposeObj2.disposeMode,
              disposeContent: this.disDialogObj.disposeContent || this.disposeObj2.disposeContent,
              disposeTime: this.disDialogObj.disposeTime || this.disposeObj2.disposeTime,
            }
          }
        }
        if(!data.content.carryOut.disposeMode){
          this.$message.warning('处置方式不能为空')
          return
        }
        if(!data.content.carryOut.disposeContent){
          this.$message.warning('处置内容不能为空')
          return
        }
        if(!data.content.carryOut.disposeTime){
          this.$message.warning('处置时间不能为空')
          return
        }
      }
      if (this.step == 2) {//处置完成2
        data = {
          id: this.disId,
          content: {
            disposeComplete: {
              disposeResult: this.disDialogObj.disposeResult || this.disposeObj3.disposeResult,
              disposeContent: this.disDialogObj.disposeContent || this.disposeObj3.disposeContent,
              completeTime: this.disDialogObj.completeTime || this.disposeObj3.completeTime
            }
          }
        }
        // console.log(data);
        if(!data.content.disposeComplete.disposeResult){
          this.$message.warning('处置结果不能为空')
          return
        }
        if(!data.content.disposeComplete.disposeContent){
          this.$message.warning('处置内容不能为空')
          return
        }
        if(!data.content.disposeComplete.completeTime){
          this.$message.warning('完成时间不能为空')
          return
        }
      }
      let res = await updateDispose(data)
      if (res.data.code == 200) {
        this.$message.success('处置成功')
        this.disposeVisible = false
        this.disposeRecord()
        if (show) {
          show.is_dis_edit = true
        }
      }
    },
    disEditShow2(item, val, obj) {
      console.log(item);
      this.step = val
      this.disId = obj.id
      this.disposeObj2 = {
        disposeMode: item.disposeMode + '',
        disposeContent: item.disposeContent,
        disposeTime: item.disposeTime,
      }
      this.disDialogObj = {
        disposeMode: '',
        disposeTime: '',
        disposeContent: '',
        disposeResult: '',
        completeTime: ''
      }
      item.is_dis_edit = false
    },
    disEditHide2(item) {
      item.is_dis_edit = true
    },

    disEditShow3(item, val, obj) {
      console.log(item);
      this.step = val
      this.disId = obj.id
      this.disposeObj3 = {
        disposeResult: item.disposeResult + '',
        disposeContent: item.disposeContent,
        completeTime: item.completeTime,
      }
      this.disDialogObj = {
        disposeMode: '',
        disposeTime: '',
        disposeContent: '',
        disposeResult: '',
        completeTime: ''
      }
      item.is_dis_edit = false
    },
    disEditHide3(item) {
      item.is_dis_edit = true
    },
    // 误判调整
    async misjudge(item) {
      let data = {
        id: item.id,
        content: {
          misjudgmentAdjustment: {
            abnormalStatus: 0,
            changeReason: '误判',
            changeTime: this.$main.timeCycle(new Date(),'yyyy-MM-dd HH:mm:ss')
          }
        }
      }

      let res = await updateDispose(data)
      if (res.data.code == 200) {
        this.$message.success('处置成功')
        this.disposeRecord()
      }
    },
    //tab选择模块事件
    handleClick(tab, event) {
      console.log(tab, event);
    },
    imgLoad(e) {
      console.log(e)
      this.imgLoading = false
    },
    back() {
      this.$router.push("/harmful");
    },
    toDetail(inx, id) {
      if (inx == 1) {
        // 相似列表  
        this.$main.routeResolve("/harmfulDetail", { id })
      } else if (inx == 2) {
        // 开展处置 go 问题平台详情页
        this.$main.routeResolve("/questionDetail", { item: this.myinfo });
      }
    },
    //处置记录
    async disposeRecord() {
      let { dataId } = this
      let res = await disposeRecord(dataId)
      if (res.data.code == 200) {
        this.chuzhiData = res.data.data.map(v => {
          if (v.content.discoveryAbnormal) v.content.discoveryAbnormal.is_dis_edit = true
          if (v.content.carryOut) v.content.carryOut.is_dis_edit = true
          if (v.content.disposeComplete) v.content.disposeComplete.is_dis_edit = true
          return v
        })
      }
    },
    //  详情
    async getdetail() {
      const { dataId } = this;
      const Res = await detail(dataId);
      const { data } = this.$main.getResData(Res);
      data.content = data.content ? data.content.replace(/\n/g, "<br/>") : "";
      this.myinfo = data;
      this.imgUrl = data.imagePath;
      this.ifOpen = false;
      let { artificialMessageTypesName, machineMessageTypesName } = data;
      if (data) {
        this.similarTitleFn(data);
      }
      if (artificialMessageTypesName.length > 0) {
        this.xinxiTypeList = artificialMessageTypesName.split(",");
      } else if (machineMessageTypesName.length > 0) {
        this.xinxiTypeList = machineMessageTypesName.split(",");
      } else {
        this.xinxiTypeList = [];
      }
    },
    //  调用相似接口
    async similarTitleFn(obj) {

      const { accountId, titleHash, id } = obj;
      // obj.returnSimilarData =true
      const Res = await similarTitle([
        {
          returnSimilarData: true,
          accountId,
          titleHash,
          id,
          similarDataNum: 10,
        },
      ]);
      this.likeList = []
      const { data } = this.$main.getResData(Res);
      if (data.length == 0 || !data) return
      this.likenum = Number(data[0].num);
      const { similarData } = data[0]
      similarData.forEach((v) => {
        console.log(v.artificialMessageTypesName && v.artificialMessageTypesName.length > 0
          ? v.artificialMessageTypesName.split(",")
          : v.machineMessageTypesName && v.machineMessageTypesName.length > 0
            ? v.machineMessageTypesName.split(",")
            : [])
        // console.log(v.machineMessageTypesName)
        v.xinxiTypeList =
          v.artificialMessageTypesName && v.artificialMessageTypesName.length > 0
            ? v.artificialMessageTypesName.split(",")
            : v.machineMessageTypesName && v.machineMessageTypesName.length > 0
              ? v.machineMessageTypesName.split(",")
              : [];
      });
      this.likeList = similarData;
    },
    deleteFnMy(item) {
      this.$refs.openDialog.openDialog(item);
    },
    async operation({ type, item }) {
      // 操作类别: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6撤销收藏
      // 操作类别-type: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6 取消收藏
      const { id } = item;
      const Res = await update({
        dataIds: id,
        operationType: type,
      });
      const { code } = Res.data;
      if (code == 200) {
        let typeText = "";
        if (type == 1) {
          typeText = "已收藏";
          item.collectFlag = true;
        } else if (type == 6) {
          typeText = "取消收藏";
          item.collectFlag = false;
        } else if (type == 2) {
          typeText = "成功删除";
          this.$router.push("/harmful");
          window.close()
        }
        this.$message({
          message: `${typeText ? typeText : "操作成功"}`,
          type: "success",
        });
      } else {
        this.$message.error("操作失败!");
      }
    },
    //截图
    async createImgTask() {
      this.loadingImg = true
      let data = {
        dataId: this.dataId,
        type: 3,  //平台台账1，发布内容2，有害信息3，问题平台4，专项行动5，错误表述6，监测方案7
        url: this.myinfo.sourceUrl,//信息网址
        userId: JSON.parse(window.localStorage.getItem('user')).userId
      }
      console.log('开始建立WebSocket连接');
      this.initWebSocket()
      this.$message.warning('正在截图,请稍等')
      // this.websock.onmessage
      await this.$main.mySetTimeOut(2000)
      let res = await createImgTask(data)
      if (res.data.code !== 200) {
        this.$message.error('截图失败1')
      }
    },
    //-----------------------------------------------------------
    //初始化weosocket
    initWebSocket() {
      if (typeof (WebSocket) === "undefined") {
        alert("您的浏览器不支持WebSocket")
        return false
      }
      const wsurl = "wss://api.sd.wangxinban.cn/websocket/" + window.localStorage.getItem("token")// 正式地址
      console.log(window.localStorage.getItem("token"))
      // const wsurl = "ws://172.16.76.242:8301/websocket/"+JSON.parse(sessionStorage.getItem('userInfo')).userId   // 测试地址
      this.websock = new WebSocket(wsurl)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    //连接成功
    websocketonopen() {
      console.log('WebSocket连接成功')
      // let action = { message: "1" };
      // this.websocketsend(JSON.stringify(action));
    },
    //接收后端返回的数据
    websocketonmessage(e) {
      console.log('WebSocket返回数据');
      let dataJson = JSON.parse(e.data)
      console.log(dataJson)
      this.imgUrl = dataJson.imagePath
      this.$message.success('截图成功')
      this.loadingImg = false
      // 在这里使用后端返回的数据，对数据进行处理渲染
    },
    //连接建立失败重连
    websocketonerror(e) {
      console.log(`连接失败的信息：`, e)
      this.initWebSocket() // 连接失败后尝试重新连接
    },
    //关闭连接
    websocketclose(e) {
      console.log('断开连接', e)
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
  },
  beforeDestroy() {
    //关闭websocket连接
    this.websocketclose()
  },
};
</script>
<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;
        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;
          .shadow {
            height: 74px;
            width: 57px;
          }
        }
        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }
        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;
          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }
          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
        .early-warning {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: auto;
          margin: 16px 0px;
          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }
          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }
          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: auto;
          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .shadow2 {
          height: 100%;
          width: 170px;
        }
        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }
      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px;
        position: relative;
        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }
      .content {
        width: 1164px;
        height: auto;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .disposal {
      width: 100%;
      min-height: 234px;
      background: #ffffff;
      border-radius: 4px;
      padding: 16px 40px;
      ::v-deep {
        .el-tabs__item {
          font-size: 16px;
          margin-bottom: 12px;
        }
        .el-tabs__active-bar {
          background-color: #f89d55;
        }
        .el-tabs__nav {
          .is-active {
            color: #f89d55;
          }
          .el-tabs__item {
            &:hover {
              color: #f89d55;
            }
          }
        }
        .el-card__header {
          background-color: #f4f5fa;
          padding: 12px 24px;
        }
        .el-card__body {
          padding: 0;
        }
        .el-col {
          font-size: 14px;
        }
        .el-row {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
        }
        .el-col-18 {
          .input {
            width: 200px;
          }
          .el-select {
            width: 200px;
          }
          .el-date-editor {
            width: 200px;
          }
        }
      }
      #pane-1 {
        padding: 40px 200px 26px 200px;
        font-size: 16px;
      }
      // 处置详情
      .el-tab-pane {
        .el-card {
          margin-bottom: 16px;
          .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-weight: 800;
            }
            .right-btn-div {
              display: flex;
              .btn {
                width: 102px;
                height: 32px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                margin-right: 16px;
                cursor: pointer;
              }
              .btn1 {
                background: #fff2f3;
                border: 1px solid #e60b1e;
                color: #e60b1e;
              }
              .btn2 {
                background: #fff1e8;
                border: 1px solid #f58030;
                color: #ed731f;
              }
              .btn3 {
                background: #dff7ef;
                border: 1px solid #0fbf82;
                color: #02bc7c;
                margin-right: 0;
              }
            }
          }
          .dis-cont {
            display: flex;
            justify-content: start;
            min-height: 100px;
            .dis-type {
              width: 33%;
              padding: 27px 32px;
              .first-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .type-name {
                  font-weight: 550;
                }
                .type-edit {
                  width: 70px;
                  height: 32px;
                  background: #fff1e8;
                  border-radius: 4px;
                  font-size: 14px;
                  color: #ed731f;
                  line-height: 32px;
                  text-align: center;
                  cursor: pointer;
                }
                .type-edit-list {
                  display: flex;
                  align-items: center;
                  .type-edit-no {
                  }
                  .type-edit-yes {
                  }
                }
                .input {
                  width: 190px;
                }
              }
              .row {
                margin-bottom: 24px;
                font-size: 14px;
              }
            }
            .divider {
              border-right: 1px solid #ebeef5;
              min-height: 100px;
            }
          }
        }
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;
            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                // background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }
              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: 800;
              }
            }
            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
.dialog-body {
  .dialog-body-li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .dialog-body-li-l {
      width: 100px;
      span {
        margin-top: 50px;
      }
    }
    .dialog-body-li-r {
      // width: calc(100% - 100px);
      width: auto;
      .el-select {
        width: 220px;
      }
      .el-input {
        width: 200px;
      }
      .el-textarea {
        width: 200px;
      }
    }
  }
}
.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.type {
  cursor: pointer;
  width: 114px;
  min-height: 28px;
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 4px 10px;
  background: #ff4b1f;
  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    color: #c0c4cc !important;
    cursor: not-allowed !important;
    background-image: none !important;
    background-color: #fff !important;
    border-color: #ebeef5 !important;
  }
}
</style>